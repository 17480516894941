import config from "config";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import { CritiqueStatus, CurrentUserState } from "types";
import "./EditCritique.scss";
import ReactQuill, { Quill } from "react-quill";
import "quill-emoji/dist/quill-emoji.css";
import * as Emoji from "quill-emoji";
import { countWords } from "utils";
import { toast } from "react-toastify";
import { AnnotatedContent, SubmissionResponse, Critique } from "../commons";

Quill.register("modules/emoji", Emoji);

export default function EditCritique() {
  const authenticationContext = useContext(AuthenticationContext);
  const { id } = useParams() as any;
  // get search params
  const params = new URLSearchParams(window.location.search);
  const [submission, setSubmission] = useState<SubmissionResponse | undefined>(
    undefined
  );
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [critique, setCritique] = useState<Critique | null>(null);
  const userCanCritique = [CritiqueStatus.DRAFT].includes(
    critique?.status || 0
  );
  const [isSplitView, setIsSplitView] = useState(false);
  const quillRef = useRef<ReactQuill | null>(null);
  const history = useHistory();

  async function loadSubmission() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/`;
    const response: SubmissionResponse | undefined = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });

    setSubmission(response);
  }

  async function loadCritique() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/`;
    const response: Critique = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setCritique(response);
  }

  const submitCritique = async () => {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/`;
    const quill = quillRef.current?.getEditor();
    const contents = quill?.root.innerHTML;

    const wordCount = countWords(contents || "");
    if (
      !userCanCritique ||
      (submission?.group.min_critique_words &&
        wordCount < submission?.group.min_critique_words)
    ) {
      toast.error(
        `Critique must be at least ${submission?.group.min_critique_words} words. Current word count: ${wordCount}`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    }

    const response: Critique = await sendRequest({
      url,
      method: "POST",
      token: authenticationContext.token || "",
      body: JSON.stringify({
        ...critique,
        contents,
        status: CritiqueStatus.FINISHED_APPROVED,
      }),
    });
    toast.success("Critique submitted successfully.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    history.push(
      "/" + (submission?.group.url.substring(1) || "") + "?critiqued=true"
    );
  };

  useEffect(() => {
    if (params.get("reopen") === "true") {
      window.location.href = `/critique/edit/${id}/`;
    }
  }, [params]);

  const saveCritique = async (showToast?: boolean) => {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/`;
    const quill = quillRef.current?.getEditor();
    const contents = quill?.root.innerHTML;

    const response: Critique = await sendRequest({
      url,
      method: "POST",
      token: authenticationContext.token || "",
      body: JSON.stringify({
        ...critique,
        contents,
      }),
    });
    showToast &&
      toast.success("Critique saved successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
  };

  useEffect(() => {
    setSubmission(undefined);
  }, []);

  useEffect(() => {
    if (authenticationContext.token && id) {
      loadSubmission();
      loadCritique();
    }
  }, [id, authenticationContext]);

  function updateWordCount() {
    const textContent = quillRef.current?.getEditor().root.innerHTML;
    const wordCount = countWords(textContent || "");
    const element = document.querySelector(".word-count-holder");
    if (element) {
      element.innerHTML = wordCount.toString();
    }
    const submitButton = document.querySelector(
      ".critique-submit-btn"
    ) as HTMLButtonElement;
  }

  useEffect(() => {
    if (submission && quillRef.current) {
      updateWordCount();
      setTimeout(updateWordCount, 1000);
    }
  }, [submission, quillRef]);
  const slug = submission?.group.name.replace(/\s+/g, "-").toLowerCase();

  return (
    <>
      <Container
        style={{ marginBottom: isSplitView ? 186 : 0, overflowY: "hidden" }}
      >
        <Row style={{ fontSize: 15 }}>
          <Col xs={12} className="mb-2">
            <Link
              to={`/group/${submission?.group.id}/${slug}/`}
              id="returntogroup"
            >
              {" "}
              Return to {submission?.group.name}
            </Link>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            sm={8}
            className="justify-between items-start pt-4 pb-2 border-l border-r border-t"
          >
            <div className="pl-4">
              <h4
                style={{
                  textTransform: "capitalize",
                  fontSize: "22px",
                }}
              >
                {submission?.document.title}
              </h4>
              <h5 className="text-gray-500">
                {submission?.document.author.pen_name}
              </h5>
            </div>
          </Col>
          <Col xs={0} md={4}></Col>
        </Row>
        {submission && critique && (
          <AnnotatedContent
            submissionId={id}
            submission={submission}
            documentId={submission?.document.id || 0}
            content={submission?.document.contents}
            userData={userState.data}
            notes={submission?.notes}
            users={[userState.data]}
            canDelete={userCanCritique}
          />
        )}
      </Container>
      <div
        className="pb-4"
        style={{
          background: "#efefef",
          position: isSplitView ? "fixed" : "relative",
          bottom: 0,
        }}
      >
        <Container className="critique-container w-full pt-4 pb-6 px-0">
          <form
            onSubmit={(e) => {
              const form = e.currentTarget;
              e.preventDefault();
              submitCritique();
            }}
          >
            <Row>
              <Col xs={12} md={8}>
                <h2>Overall Feedback</h2>
              </Col>
              <Col xs={12} md={3} className="text-right">
                <img
                  src={`${config.BASE_API_URL}static/img/screen-${
                    isSplitView ? "join" : "split"
                  }.png`}
                  width={45}
                  height={45}
                  className="rounded cursor-pointer"
                  onClick={() => setIsSplitView(!isSplitView)}
                />
              </Col>
              <Col xs={12} md={2}></Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={11} className="pr-0">
                {critique !== null && (
                  <ReactQuill
                    placeholder="Write your overall feedback."
                    theme="snow"
                    defaultValue={critique.contents}
                    modules={{
                      toolbar: [["emoji"]],
                      "emoji-toolbar": true,
                      "emoji-shortname": true,
                    }}
                    onChange={(value) => {
                      updateWordCount();
                      setTimeout(() => {
                        const quill = quillRef.current?.getEditor();
                        const contents = quill?.root.innerHTML;
                        if (contents === value) {
                          saveCritique();
                        }
                      }, 500);
                    }}
                    ref={quillRef}
                  />
                )}
              </Col>
              <Col></Col>
            </Row>

            <Row>
              <Col xs={12} md={11}>
                <div className="text-right text-sm pt-2">
                  <span className="word-count-holder"></span> words{" "}
                  {Boolean(submission?.group.min_critique_words) &&
                    `(min. ${submission?.group.min_critique_words})`}
                </div>
              </Col>
            </Row>
            {userCanCritique && (
              <Row className="pt-2">
                <Col xs={12} md={5}>
                  <Button
                    className="purple mr-2 ph-4 pv-2 critique-submit-btn"
                    type="submit"
                  >
                    Submit critique
                  </Button>
                  <Button
                    className="transparent ml-2"
                    onClick={() => saveCritique(true)}
                    style={{ backgroundColor: "transparent" }}
                  >
                    Save and finish later
                  </Button>
                </Col>
                <Col xs={12} md={6}>
                  <p className="text-xs text-muted-foreground">
                    All comments save automatically. Submitting makes your
                    comments visible to the group. You may edit your critique
                    once submitted.
                  </p>
                </Col>
              </Row>
            )}
          </form>
        </Container>
      </div>
    </>
  );
}
