import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import AuthenticationContext from "contexts/AuthenticationContext";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select, { MultiValue } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import config from "config";
import useFetch from "custom-hooks/useFetch";
import GenreSelector from "components/genre-selector/GenreSelector";
import { getGenreListIds } from "utils";

import "../GroupEditProfile.scss";
import { FullGroup, BaseUser } from "types";
import { typeOfGroupOptions } from 'options/TypeOfGroup';
import { sexualContentOptions } from 'options/SexualContent';
import { violenceOptions } from 'options/Violence';
import { critiqueManagerOptions } from 'options/CritiqueManager';
import CharCountInput from "components/char-count-input/CharCountInput";


const GroupEditProfileForm = ({ groupData }: { groupData?: FullGroup }) => {
  const history = useHistory();
  const isCreating = !groupData;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FullGroup>({
    defaultValues: {
      ...groupData,
      type_of_group: groupData?.type_of_group ?? 0,
      min_size: groupData?.min_size ?? 3,
      max_size: groupData?.max_size ?? 8,
      critique_estimate: groupData?.critique_estimate ?? 3,
      critique_time_window: groupData?.critique_time_window ?? 14,
      review_time_window: groupData?.review_time_window ?? 35,
      min_critique_words: groupData?.min_critique_words ?? 0,
      critique_manager: groupData?.critique_manager ?? 1,
      is_recruiting: groupData?.is_recruiting ?? true,
    },
    mode: "onBlur",
  });

  const [state, fetchData] = useFetch();
  const currentUser = useSelector((state: any) => state.user);
  const authenticationContext = useContext(AuthenticationContext);
  const typeOfGroup = watch('type_of_group');
  const [isMaxWordsChecked, setIsMaxWordsChecked] = useState(false);
  const [maxWords, setMaxWords] = useState<number | string>("");
  const [genres, setGenres] = useState(groupData?.genres2_set || []);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState(groupData?.avatar || "");
  const [activeUsers, setActiveUsers] = useState<BaseUser[]>();
  const [date, setDate] = useState<Date | null>(groupData?.start_date ? new Date(groupData.start_date) : null);
  const [newOwners, setNewOwners] = useState<BaseUser[]>(
    groupData?.owners || [{ id: currentUser.data.id, pen_name: currentUser.data.pen_name, email: "user@mail.com" }]
  );

  const onAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCreating) {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("avatar", file);
        if (groupData) {
          formData.append("group_id", groupData.id.toString());
        }

        try {
          const response = await axios.put(
            `${config.BASE_API_URL}group/api/change-avatar/`,
            formData,
            {
              headers: {
                Authorization: "Token " + (authenticationContext.token || ""),
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setAvatarUrl(response.data.avatar);
          toast.success("Avatar updated successfully.");
        } catch {
          toast.error("Failed to update avatar.");
        }
      }
    } else {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setAvatarUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onSubmit: SubmitHandler<FullGroup> = async (data) => {
    data.genres2_set = getGenreListIds(genres);
    data.owners = newOwners;
    if (!groupData) {
      data.owner = currentUser.data.id;
    }
    if (date) {
      data.start_date = date?.toISOString();
    }

    const url = isCreating ? "group/api/new/" : "group/api/edit/";
    const method = isCreating ? "POST" : "PUT";

    try {
      const response = await fetchData({
        url: url,
        method: method,
        token: authenticationContext.token,
        body: JSON.stringify(data),
      });

      toast.success(
        isCreating
          ? "Group created successfully."
          : "Changes saved successfully.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );

      if (selectedFile) {
        const formData = new FormData();
        formData.append("avatar", selectedFile);
        formData.append("group_id", response.id.toString());

        await axios.put(
          `${config.BASE_API_URL}group/api/change-avatar/`,
          formData,
          {
            headers: {
              Authorization: "Token " + (authenticationContext.token || ""),
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const slug = response.name.replace(/\s+/g, "-").toLowerCase();
      history.push(`/group/${response.id}/${slug}`);
    } catch (error: any) {
      // Handle errors
      let errorMessage = "Failed to save changes.";
      if (error.message) {
        let errorData;
        try {
          errorData =
            typeof error.message === "string"
              ? JSON.parse(error.message)
              : error.response.data;
        } catch (e) {
          errorData = {
            message: "An error occurred, but the details could not be parsed.",
          };
        }

        if (typeof errorData === "object" && errorData !== null) {
          if (errorData.message) {
            errorMessage = errorData.message;
          } else {
            errorMessage = Object.entries(errorData)
              .map(([field, value]) => {
                const errors = Array.isArray(value)
                  ? value.join(", ")
                  : "Unknown error";
                return `${field}: ${errors}`;
              })
              .join("\n");
          }
        }
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchData({
      url: "api/v1.0/user/active/",
      method: "GET",
      token: authenticationContext.token,
    }).then((response) => {
      setActiveUsers(response);
    });
  }, [authenticationContext.token]);

  useEffect(() => {
    if (isCreating) {
      fetchData({
        url: "api/v1.0/genres/",
        method: "GET",
        token: authenticationContext.token,
      }).then((response) => {
        setGenres(response);
      });
    }
  }, [authenticationContext.token, isCreating]);

  const transformedUsers = activeUsers?.map((user) => ({
    value: user.id,
    label: user.pen_name,
  }));

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsMaxWordsChecked(e.target.checked);
    if (e.target.checked) {
      setMaxWords("");
    } else {
      setMaxWords(0);
    }
  };

  const handleMaxWordsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxWords(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="edit-group">
      <Container className="container profile-edit">
        <Row>
          <Col md={{ span: 12, offset: 0 }}>
            <h1>{isCreating ? "CREATE A" : "EDIT"} GROUP</h1>
            {groupData && (
              <a href={groupData.url} target="_blank" rel="noopener noreferrer">
                View group
              </a>
            )}
          </Col>
        </Row>
        <div className="profile-edit">
          <Row>
            <Col md={3}>
              <h2>Basics</h2>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Group name*</label>
            </Col>
            <Col md={5}>
              <input
                type="text"
                className="form-control"
                placeholder="Group Name"
                {...register("name")}
              />
              <br></br>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>Group profile photo</label>
            </Col>
            <Col md={9}>
              <Row>
                <Col md={3} id="img-container">
                  {avatarUrl ? (
                    <img className="profile-picture" src={avatarUrl} />
                  ) : (
                    <>
                      <img
                        className="profile-picture"
                        src=""
                        style={{ display: "none" }}
                      />
                      <span className="group-profile-picture"></span>
                    </>
                  )}
                </Col>
                <Col className="col-md-7">
                  Change your group profile photo
                  <br />
                  <br />
                  <input type="file" onChange={onAvatarChange} />
                  <div id="file-uploader"></div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>Group owners*</label>
            </Col>
            <Col md={9}>
              <Select
                isMulti
                options={transformedUsers}
                defaultValue={
                  groupData && groupData.owners.length > 0
                    ? groupData.owners.map((owner: any) => ({
                        value: owner.id,
                        label: owner.pen_name,
                      }))
                    : [{ value: currentUser.data.id, label: currentUser.data.pen_name }]
                }
                onChange={(
                  selectedOption: MultiValue<{
                    value: number;
                    label: any;
                  }>
                ) => {
                  setNewOwners(
                    selectedOption.map((option) => {
                      return {
                        id: option.value,
                        pen_name: option.label,
                        email: "placeholder@example.com",
                      };
                    })
                  );
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    background: "#ebebeb",
                  }),
                  multiValue: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#005b7f",
                    color: "white",
                  }),
                  multiValueLabel: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "white",
                  }),
                  multiValueRemove: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#999",
                    ":hover": {
                      backgroundColor: "darkblue",
                      color: "#999",
                    },
                  }),
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3} className="col-md-3">
              <label>Type of Group</label>
            </Col>
            <Col md={6}>
              <Controller
                name="type_of_group"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={typeOfGroupOptions}
                    onChange={(selectedOption) => {
                      const value = selectedOption ? selectedOption.value : 0;
                      field.onChange(value);
                      setValue('type_of_group', value);
                    }}
                    value={typeOfGroupOptions.find(
                      (option) => option.value === field.value
                    )}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "#ebebeb",
                      }),
                    }}
                  />
                )}
              />
            </Col>
          </Row>

          {typeOfGroup === 1 && (
            <Row>
              <Col md={3}>
                <label>Start Date</label>
              </Col>
              <Col md={9}>
                {/* <input
                  type="date"
                  className="form-control"
                  {...register("start_date")}
                  defaultValue={groupData?.start_date}
                /> */}
                <DatePicker
                  onChange={(newVal: Date | null) => {
                    setDate(newVal);
                  }}
                  selected={date}
                  showTimeInput
                  // dateFormat="d/MMM yy HH:mm"
                  className="due-date-picker"
                  // customTimeInput={<ExampleCustomTimeInput />}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col md={3}>
              <label>Group genres*</label>
            </Col>
            <Col md={9}>
              <Controller
                name="genres2_set"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <GenreSelector
                      genres={genres}
                      onChange={(e: any) => {
                        setGenres(e);
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>

          <CharCountInput
            styledText
            control={control}
            maxLength={5000}
            inputName="description"
            register={register}
            errors={errors}
            defaultValue={groupData?.description}
            label="Group Description *"
          />

          <CharCountInput
            maxLength={1200}
            inputName="admissions_policies"
            register={register}
            errors={errors}
            defaultValue={groupData?.admissions_policies}
            label="Admissions Policies"
          />

          <CharCountInput
            maxLength={1200}
            inputName="members_requirements"
            register={register}
            errors={errors}
            defaultValue={groupData?.members_requirements}
            label="What we're looking for in members"
          />

          <Row className="row">
            <Col md={3} className="col-md-3">
              <label>Share with Inked Voices site visitors?</label>
            </Col>
            <Col md={9}>
              <input {...register("share_with_visitors")} type="checkbox" /> Yes
            </Col>
          </Row>

          <CharCountInput
            styledText
            control={control}
            maxLength={20000}
            inputName="submission_schedule"
            register={register}
            errors={errors}
            defaultValue={groupData?.submission_schedule}
            label="Submission Schedule"
          />

          <CharCountInput
            maxLength={1200}
            inputName="inactivity_policy"
            register={register}
            errors={errors}
            defaultValue={groupData?.inactivity_policy}
            label="Inactivity Policy"
          />

          <CharCountInput
            maxLength={1200}
            inputName="goal"
            register={register}
            errors={errors}
            defaultValue={groupData?.goal}
            label="Group Goals*"
          />

          <Row>
            <Col md={3}>
              <label>What is your target group size?</label>
            </Col>
            <Col md={9}>
              <Row className="row simple">
                <Col md={1}>
                  <input
                    type="number"
                    id="id_min_size"
                    {...register("min_size")}
                    defaultValue={groupData?.min_size}
                  />
                </Col>
                <Col md={1}>
                  <p className="space">to</p>
                </Col>
                <Col md={1}>
                  <input
                    type="number"
                    id="id_max_size"
                    {...register("max_size")}
                    defaultValue={groupData?.max_size}
                  />
                </Col>
                <Col md={1}>
                  <p className="space">members</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>Are you accepting new group members?</label>
            </Col>
            <Col md={9}>
              <input
                {...register("is_recruiting")}
                type="checkbox"
                checked={groupData?.is_recruiting}
              />{" "}
              Yes
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>
                Does your group have an outside site (Facebook, Meetup, etc.)?
              </label>
            </Col>
            <Col md={9} className="col-md-9">
              <Row className="simple">
                <Col md={5}>
                  <input
                    type="url"
                    {...register("facebook")}
                    className="inline-form-input"
                    placeholder="https://www.facebook.com/JoeDoe"
                  />
                  <input
                    type="url"
                    {...register("website")}
                    className="inline-form-input"
                    placeholder="https://personalwebsite.com"
                  />
                </Col>
                <Col md={5}>
                  <input
                    type="url"
                    {...register("meetup")}
                    className="inline-form-input"
                    placeholder="http://www.meetup.com/Joe-Doe/"
                  />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>

      <div className="bottom-edge-white"></div>
      <div className="prefooter-one">
        <br />
        <Container className="profile-edit">
          <Row>
            <Col md={3}>
              <h2>Submissions</h2>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>
                What level of sexual content is acceptable in group submissions?
                *
              </label>
            </Col>
            <Col md={9}>
              <ul id="id_sexual_content" className="custom-checkbox">
                {sexualContentOptions.map((option, index) => (
                  <li
                    key={`id_sexual_content_${index}`}
                    className="custom-checkbox"
                  >
                    <label className="custom-checkbox">
                      <input
                        {...register("sexual_content")}
                        type="radio"
                        value={option.value}
                        defaultChecked={
                          option.value === groupData?.sexual_content
                        }
                        className="custom-checkbox"
                      />{" "}
                      {option.label}
                    </label>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>
                What level of violence is acceptable in submissions to the
                group? *
              </label>
            </Col>
            <Col md={9}>
              <ul id="id_violence" className="custom-checkbox">
                {violenceOptions.map((option, index) => (
                  <li key={`id_violence_${index}`} className="custom-checkbox">
                    <label className="custom-checkbox">
                      <input
                        {...register("violence")}
                        type="radio"
                        value={option.value}
                        defaultChecked={option.value === groupData?.violence}
                        className="custom-checkbox"
                      />{" "}
                      {option.label}
                    </label>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <Row className="word-count">
            <Col md={3}>
              <label>Word count</label>
            </Col>
            <Col md={9}>
              Is there a maximum for submissions?
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="maximum_check"
                    checked={isMaxWordsChecked}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Yes
                  {isMaxWordsChecked && (
                    <span className="inline" id="word_input">
                      <input
                        type="number"
                        id="id_max_words"
                        value={maxWords}
                        {...register("max_words")}
                        onChange={handleMaxWordsChange}
                      />{" "}
                      words
                    </span>
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom-edge-light"></div>
      <div className="prefooter-two">
        <br />
        <Container className="profile-edit">
          <Row>
            <Col md={3}>
              <h2>Advanced Settings</h2>
            </Col>
          </Row>
          <Row className="row">
            <Col md={3}>
              <label>Critique ratio</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How many credits will group members use to submit their work? Each
              critique given earns 1 credit.
              <br />
              <input
                type="number"
                id="id_critique_estimate"
                {...register("critique_estimate")}
                defaultValue={groupData?.critique_estimate}
                style={{ textAlign: "center" }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Critique window</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How many days do group members have to critique submissions?
              <br />
              <Col md={1} className="horizontal-align">
                <input
                  type="number"
                  id="id_critique_time_window"
                  {...register("critique_time_window")}
                  defaultValue={groupData?.critique_time_window}
                  style={{ textAlign: "center" }}
                />{" "}
                Days
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Review window</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How long can members review a document after a critique period?
              <br />
              <Col md={1} className="horizontal-align">
                <input
                  type="number"
                  id="id_review_time_window"
                  {...register("review_time_window")}
                  defaultValue={groupData?.review_time_window}
                  style={{ textAlign: "center" }}
                />{" "}
                Days
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Minimum critique length</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              If there is a word count minimum for the "Overall feedback"
              section, set it here.
              <br />
              <input
                type="number"
                id="id_min_critique_words"
                {...register("min_critique_words")}
                defaultValue={groupData?.min_critique_words}
                style={{ textAlign: "center" }}
              />
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Critique manager</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How do you prefer manage the approval of critiques after deadline?
              <br />
              <Col md={3}>
                <Controller
                name="critique_manager"
                control={control}
                render={({ field }) => (
                    <Select
                      {...field}
                      options={critiqueManagerOptions}
                      onChange={(selectedOption) => {
                        const value = selectedOption ? selectedOption.value : 1;
                        field.onChange(value);
                        setValue('critique_manager', value);
                      }}
                      value={critiqueManagerOptions.find(
                        (option) => option.value === field.value
                      )}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          background: "#ebebeb",
                        }),
                      }}
                    />
                  )}
                />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 9, offset: 3 }} className="main-btn">
              {/* <input type="submit" className="btn purple" value="Save Group" /> */}
              <button type="submit" className="btn purple">
                {isCreating ? "Create Group" : "Save Group"}
              </button>
              <a
                href="{% if group_id %}{% url 'view_group2' group_id %}{% else %}{% url 'dashboard' %}{% endif %}"
                className="btn cancel"
                style={{ marginLeft: "1%" }}
              >
                Cancel
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </form>
  );
};

export default GroupEditProfileForm;
